import { Scene } from "phaser";
import i18n from "@/plugins/i18n";
import router from "@/router";

export default class LoadingScene extends Scene {
  constructor() {
    super({ key: "LoadingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.autoPlay = data.autoPlay;
    this.steps = data.steps;
    this.subjectLang = data.subjectLang;
  }

  preload() {
    // Create progress bar elements
    this.createLoadingScreen();

    // Load assets
    this.loadAssets();

    // Handle loading events
    this.setupLoadingEvents();
  }

  createLoadingScreen() {
    this.add.text(0, 0, "", {
      fontFamily: "WarungKopi, sans-serif",
    });
    this.add.text(0, 0, "", {
      fontFamily: "MouseMemoirs, sans-serif",
    });
    const progressBar = this.add.graphics();
    const progressBox = this.add.graphics();
    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRect(100, 270, 280, 50);

    const width = this.cameras.main.width;
    const height = this.cameras.main.height;

    this.loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 50,
      text: i18n.t("miniGames.general.loading") + "...",
      style: {
        font: "20px monospace",
        fill: "#000000",
      },
    });
    this.loadingText.setOrigin(0.5, 0.5);

    this.percentText = this.make.text({
      x: width / 2,
      y: height / 2 - 5,
      text: "0%",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    this.percentText.setOrigin(0.5, 0.5);

    this.assetText = this.make.text({
      x: width / 2,
      y: height / 2 + 50,
      text: "",
      style: {
        wordWrap: { width: 400, useAdvancedWrap: true },
        font: "18px monospace",
        fill: "#000000",
      },
    });
    this.assetText.setOrigin(0.5, 0.5);

    this.progressBar = progressBar;
    this.progressBox = progressBox;
  }

  loadAssets() {
    // Load stories audio
    for (let step of this.steps) {
      if (step.actionType == "goPage" || step.actionType == "restartChapter") {
        this.load.audio(
          "sfxPrimary" + step.Page.key,
          JSON.parse(step.Page.translatableVoiceover)[this.subjectLang].url
        );
        if (
          JSON.parse(step.Page.translatableVoiceover)[i18n.locale] &&
          this.subjectLang != i18n.locale
        ) {
          this.load.audio(
            "sfxSecondary" + step.Page.key,
            JSON.parse(step.Page.translatableVoiceover)[i18n.locale].url
          );
          step.Page.isSecondarySfx = true;
        }
      }

      if (step.actionType == "goQuestion") {
        for (let question of step.Page) {
          this.load.audio(
            "sfxPrimary" + question.key,
            JSON.parse(question.translatableVoiceover)[this.subjectLang].url
          );
          if (
            JSON.parse(question.translatableVoiceover)[i18n.locale] &&
            this.subjectLang != i18n.locale
          ) {
            this.load.audio(
              "sfxSecondary" + question.key,
              JSON.parse(question.translatableVoiceover)[i18n.locale].url
            );
            question.isSecondarySfx = true;
          }


          for (let option of question.ChapterQuestionOption) {
            this.load.audio(
              "sfxPrimary" + option.chapterQuestionKey + '_' + option.id,
              JSON.parse(option.translatableVoiceover)[this.subjectLang].url
            );
            if (
              JSON.parse(option.translatableVoiceover)[i18n.locale] &&
              this.subjectLang != i18n.locale
            ) {
              this.load.audio(
                "sfxSecondary" + option.chapterQuestionKey + '_' + option.id,
                JSON.parse(option.translatableVoiceover)[i18n.locale].url
              );
              option.isSecondarySfx = true;
            }
            
          }
        }
      }
    }

    // Load stories images
    for (let step of this.steps) {
      if (step.actionType == "goPage" || step.actionType == "restartChapter") {
        this.load.image(
          "imgPrimary" + step.Page.key,
          JSON.parse(step.Page.translatableImage)[this.subjectLang].url
        );
        if (
          JSON.parse(step.Page.translatableImage)[i18n.locale] &&
          this.subjectLang != i18n.locale
        ) {
          this.load.image(
            "imgSecondary" + step.Page.key,
            JSON.parse(step.Page.translatableImage)[i18n.locale].url
          );
          step.Page.isSecondaryImg = true;
        }
      }

      if (step.actionType == "goQuestion") {
        for (let question of step.Page) {
          this.load.image(
            "imgPrimary" + question.key,
            JSON.parse(question.translatableImage)[this.subjectLang].url
          );
          if (
            JSON.parse(question.translatableImage)[i18n.locale] &&
            this.subjectLang != i18n.locale
          ) {
            this.load.image(
              "imgSecondary" + question.key,
              JSON.parse(question.translatableImage)[i18n.locale].url
            );
            question.isSecondaryImg = true;
          }
        }
      }
    }

    // for (let step of this.steps) {
    //   if (step.actionType == "goPage") {
    //     this.load.image("img" + step.Page.key, step.Page.image.url);
    //   }
    // }

    //*** load images ***//
    // general Images
    // buttons
    this.load.image(
      "imgCloseBtn",
      "/assets/gameAssets/common/images/btn-close.png"
    );
    this.load.image("imgOkBtn", "/assets/gameAssets/common/images/btn-ok.png");
    this.load.image(
      "imgOptionBtn",
      "/assets/gameAssets/common/images/btn-option.png"
    );
    this.load.image(
      "imgSettingBtn",
      "/assets/gameAssets/common/images/btn-setting.png"
    );

    this.load.image(
      "imgNextCtrlBtn",
      "/assets/gameAssets/common/images/right.png"
    );
    this.load.image(
      "imgPrevCtrlBtn",
      "/assets/gameAssets/common/images/left.png"
    );
    this.load.image(
      "imgPlayCtrlBtn",
      "/assets/gameAssets/common/images/play.png"
    );
    this.load.image(
      "imgPauseCtrlBtn",
      "/assets/gameAssets/common/images/pause.png"
    );
    this.load.image(
      "imgToggleOnBtn",
      "/assets/gameAssets/common/images/toggle-on.png"
    );
    this.load.image(
      "imgToggleOffBtn",
      "/assets/gameAssets/common/images/toggle-off.png"
    );

    this.load.image("imgHamochiTalk", "/assets/gameAssets/common/tutorial/hamochi-talk.png");
    this.load.image("imgHamochiHappy", "/assets/gameAssets/common/tutorial/hamochi-happy.png");
    this.load.image("imgHamochiSad", "/assets/gameAssets/common/tutorial/hamochi-sad.png");

    // panel bg
    this.load.image(
      "imgSettingPanel",
      "/assets/gameAssets/common/images/panel-setting.png"
    );

    // others
    this.load.image(
      "imgAnswerTag",
      "/assets/gameAssets/common/images/answer-tag.png"
    );
    this.load.image(
      "imgCorrectAns",
      "/assets/gameAssets/common/images/answer-correct.png"
    );
    this.load.image(
      "imgWrongAns",
      "/assets/gameAssets/common/images/answer-wrong.png"
    );
    this.load.image(
      "imgCorrectMark",
      "/assets/gameAssets/common/images/mark-correct.png"
    );
    this.load.image(
      "imgWrongMark",
      "/assets/gameAssets/common/images/mark-wrong.png"
    );    

    // //*** Load Sprite Sheets ***//

    // Common
    this.load.spritesheet(
      "sptAnswerPanel",
      "/assets/gameAssets/common/sprites/panel-answer.png",
      {
        frameWidth: 480,
        frameHeight: 120,
      }
    );

    this.load.spritesheet(
      "sptStar",
      "/assets/gameAssets/common/sprites/star.png",
      {
        frameWidth: 96,
        frameHeight: 96,
      }
    );

    // //*** load audio files ***//
    // general sfxs
    this.load.audio(
      "sfxCorrectAnswer",
      "/assets/gameAssets/common/sounds/answer-correct.wav"
    );
    this.load.audio(
      "sfxWrongAnswer",
      "/assets/gameAssets/common/sounds/answer-wrong.wav"
    );
    this.load.audio(
      "sfxScoreIncrease",
      "/assets/gameAssets/common/sounds/score-increase.wav"
    );
    this.load.audio(
      "sfxSimpleClick",
      "/assets/gameAssets/common/sounds/simple-click.wav"
    );
    this.load.audio(
      "sfxFailure",
      "/assets/gameAssets/common/sounds/game-failure.wav"
    );
    this.load.audio(
      "sfxSuccess",
      "/assets/gameAssets/common/sounds/game-success.wav"
    );
    this.load.audio("sfx_A", "/assets/gameAssets/common/sounds/_A.wav");
    this.load.audio("sfx_B", "/assets/gameAssets/common/sounds/_B.wav");
    this.load.audio("sfx_C", "/assets/gameAssets/common/sounds/_C.wav");
    this.load.audio("sfxClose", "/assets/gameAssets/common/sounds/close.wav");
    this.load.audio("sfx_QS", "/assets/gameAssets/common/sounds/QS.wav");
    this.load.audio("sfx_QE1", "/assets/gameAssets/common/sounds/QE1.wav");
    this.load.audio("sfx_QE2", "/assets/gameAssets/common/sounds/QE2.wav");
    this.load.audio("sfx_QE3", "/assets/gameAssets/common/sounds/QE3.wav");
    this.load.audio("sfx_QE4", "/assets/gameAssets/common/sounds/QE4.wav");
  }

  setupLoadingEvents() {
    this.load.on("progress", this.updateProgress, this);
    this.load.on("fileprogress", this.updateFileProgress, this);
    this.load.on("complete", this.completeLoading, this);
  }

  updateProgress(value) {
    this.percentText.setText(parseInt(value * 100) + "%");
    this.progressBar.clear();
    this.progressBar.fillStyle(0x000000, 1);
    this.progressBar.fillRect(110, 280, 260 * value, 30);
  }

  updateFileProgress(file) {
    this.assetText.setText("Loading asset: " + file.key);
  }

  completeLoading() {
    this.progressBar.destroy();
    this.progressBox.destroy();
    this.loadingText.destroy();
    this.percentText.destroy();
    this.assetText.destroy();
  }

  create() {
    this.registry.destroy(); // Clear the registry
    this.events.off(); // Remove all active events
    this.scene.stop("LoadingScene"); // Stop this scene
    this.nextStep("success");
  }

  nextStep(conditionKey) {
    let nextStep = this.steps.find((step) => step.order === 1);

    if (nextStep) {
      if (nextStep.conditionKey) {
        nextStep = this.steps.findLast(
          (step) =>
            step.order === this.currentStep.order + 1 &&
            step.conditionKey == conditionKey
        );
      }

      let sceneObject = {
        avatar: this.avatar,
        autoPlay: this.autoPlay,
        steps: this.steps,
        currentStep: nextStep,
        subjectLang: this.subjectLang,
      };

      if (nextStep.actionType == "goPage" || nextStep.actionType == "restartChapter")
        this.scene.start("StoryScene", sceneObject);

      if (nextStep.actionType == "goMap")
        this.scene.start("MapScene", sceneObject);

      if (nextStep.actionType == "goGame")
        this.scene.start("GameScene", sceneObject);

      if (nextStep.actionType == "goQuestion") {
        sceneObject.currentProggressStep = 0;
        this.scene.start("QuizScene", sceneObject);
      }

      if (nextStep.actionType == "goItem")
        this.scene.start("ItemScene", sceneObject);

    } else {
      this.scene.stop();
      router.push({
        name: "PageABCBookChapters",
        params: { bookKey: this.steps[0].Chapter.bookKey },
      });
    }
  }
}
